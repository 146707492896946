import { Controller } from 'stimulus'
import Swiper, { Navigation, Pagination } from 'swiper'

export default class extends Controller {
  connect() {
    Swiper.use([Navigation, Pagination])

    new Swiper(
      this.element,
      {
          allowTouchMove: false,
          loop: true,
          navigation: {
              nextEl: '.page__banner__arrow--next',
              prevEl: '.page__banner__arrow--prev'
          }
      }
    )
  }
}
